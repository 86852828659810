/**
* Custom modification to the theme
* Refer: https://github.com/rsuite/rsuite/blob/master/src/styles/variables.less
*/
/* @import "~react-image-gallery/styles/css/image-gallery.css"; */

:root{

  --base-color: #ffb900;
  
  --border-radius-base: 8px;
  --line-height-computed: 30px;
  --line-height-large-computed: 60px;
  --btn-primary-color: #ffffff;
  --font-family-base: 'Josefin Sans', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', sans-serif;
  --small-steps-description-margin-top: 0px;
  --steps-description-margin-top: 0px;
  --nav-item-active-font-color: var(--base-color);
  --nav-item-click-font-color: --B800;
  --nav-item-font-default-color: --B800;
  --panel-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.02), 0 0 10px rgba(0, 0, 0, 0.06);
  --form-group-margin-bottom: 12px;
  --input-border-focus: var(--base-color);
  --drawer-content-spacing: 30px;
  --drawer-body-margin: 20px;
  --state-success-bg: white;
  --state-info-bg: white;
  --state-warning-bg: white;
  --state-error-bg: white;
  --sidenav-default-width: 80px;
  --uploader-item-hover-background-color: white;
  --nav-bar-default-bg: #f8f8f8;
  --btn-ghost-color: var(--base-color);
  --btn-ghost-border-color: var(--base-color);
}

.rs-alert-item-content {
  padding: 14px 40px;
}

.rs-alert-item-wrapper {
  margin-left: auto;
}

.rs-alert-container {
  left: auto;
  right: 1% !important;
}

.rs-control-label {
  font-size: 15px;
  font-weight: bold;
}

.rs-panel {
  background: #fff;
}

::selection {
  background-color: var(--base-color);
  color: #fff;
}
body,
html,
#__next,
main {
  margin: 0;
  padding: 0;
  height: 100%;
}

#onboarding-main-container {
  display: flex;
  flex-direction: column;
}

.rs-btn-ghost.rs-btn-grey {
  color: grey;
  border: 1px solid grey;
  background-color: transparent;
}

.rs-input-number-btn-group-vertical {
  display: none;
}
.pace .pace-progress {
  background: var(--base-color);
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

.rs-btn-primary {
  color: #fff;
  font-weight: 900;
}
.rs-btn-primary:hover{
  color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.02), 0 0 10px rgba(0, 0, 0, 0.06);
}
.rs-btn-primary:focus{
  color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.02), 0 0 10px rgba(0, 0, 0, 0.06);
}
@media only screen and (min-width: 250px) {
  .rs-modal.property-modal {
    width: 90%;
  }
}
@media only screen and (min-width: 500px) {
  .rs-modal.property-modal {
    width: 70%;
  }
}
@media only screen and (min-width: 992px) {
  .rs-modal.property-modal {
    width: 50%;
  }
}

.rs-picker-cascader-menu-item {
  padding: 4px 12px;
  font-size: 0.9em;
}

.rs-picker-search-bar {
  padding: 12px !important;
}

/* .rs-picker-search-bar .rs-picker-search-bar-input {
  padding: 0px 12px !important;
} */

.rent-estimate.rs-picker-input > div {
  width: 100%;
}
@media screen and (min-width: 992px) {
  #side-nav-779,
  #icon-779 {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  #side-bar-780 {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .prop-status-right,
  .property-actions {
    display: none;
  }
  .prop-status-top {
    display: flex;
  }

  .view-details-top {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (min-width: 481px) {
  .prop-status-top,
  .view-details-top,
  .action-icon-property-actions-top {
    display: none;
  }
}

.side-nav-open {
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    opacity 0.55s ease;
}
.side-nav-close {
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    opacity 0.55s ease;
  transform: translate(-100%, 0);
}

#side-nav-779 {
  background-color: white;
  z-index: 100;
  width: 100%;
  max-width: 400px;
  position: fixed;
  height: 100%;
}

@media screen and (min-width: 481px) {
  .rent-estimator-field,
  .fg-rent-est,
  .rs-input-group.rs-input-number.rent-estimator-field,
  .fg-rent-est > .rs-form-control-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .rent-estimator-field,
  .fg-rent-est,
  .fg-rent-est > .rs-form-control-wrapper > .rent-estimator-field,
  .fg-rent-est > .rs-form-control-wrapper {
    width: 100%;
  }
  .sm-field {
    display: flex !important;
    flex-direction: column;
    align-items: flex-end;
  }
}

/* // notif badge styling */
.main-bellCount-2gBAy {
  right: 15px;
  top: 2px;
  text-align: center;
  border: 2px solid #ffffff;
  background-color: #ffb300;
  border-radius: 40%;
}


@media screen and (max-width: 480px) {
  .xs-ticket-action {
    padding: 0px 12px 5px 0px;
  }
  .xs-issue {
    font-size: 0.8em !important;
  }
}

.rs-uploader-file-item-text {
  margin-left: -20px;
}

.rs-picker-default .rs-picker-toggle {
  z-index: 0;
}

/* new work sk dashboard */
input.rs-input.rs-input-sm {
  padding: 9px 11px !important;
}
a.rs-btn.rs-btn-default.rs-picker-toggle.rs-btn-xs {
  padding: 6px 28px 6px 7px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret, .rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean{
  top: 6px !important;
  right: 28px ;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value{
  color: #e0ac00;
}
/* leasing buttons */

button.rs-btn.rs-btn-primary.rs-btn-icon.rs-btn-icon-placement-left.rs-btn-icon-circle.rs-btn-xs{
  background-color: #ffb900;
  color: #fff;
  width: 34px;
  height: 34px;
}
button.rs-btn.rs-btn-primary.rs-btn-icon.rs-btn-icon-placement-left.rs-btn-icon-circle.rs-btn-xs>.rs-icon{
  padding: 6px 0px;
  width: 34px;
}
button.rs-btn.rs-btn-primary.add-button-styled.p-4.rs-btn-xs{
  background-color: #ffb900;
  color: #fff;
}
/* leasing form */
.rs-input:focus, .rs-input:hover {
  border-color: #ffb900;
  outline: 0;
}
.rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled):hover {
  border-color: #ffb900;
}
/* leasing form btn */
.rs-btn-link{
  color: inherit;
}
button.rs-btn.rs-btn-link.rs-btn-xs:hover {
  /* background: #f7f7fa; */
  color: inherit;
  text-decoration: none;
}
button.rs-btn.rs-btn-ghost.rs-btn-xs.rs-btn-disabled {
  border: 1px solid #ffb900;
}
button.rs-btn.rs-btn-primary.rs-btn-sm.rs-btn-disabled {
  color: #fff;
  background: #ffb900;
}
input.rs-input.rs-input-xs {
  padding: 6px 11px;
}
/* income management btn */
button.rs-btn.rs-btn-ghost.rs-btn-xs {
  color: #ffb900;
  /* border: 1px solid #ffb900; */
}
button.rs-btn.rs-btn-ghost.rs-btn-xs:hover {
  color: #e0ac00;
  /* border: 1px solid #e0ac00; */
}
/* properties overview */
.rs-help-block{
  min-height: 30px;
}
/* properties document btn */
button.rs-btn.rs-btn-ghost.rs-btn-sm {
  color: #e0ac00;
  border: 1px solid #e0ac00;
}

button.rs-btn.rs-btn-ghost.rs-btn-sm:hover {

color: #c29800;
border-color: #c29800;
}
/* properties instructions input */
a.rs-btn.rs-btn-default.rs-picker-toggle:hover{
  border-color: #ffb900;
}
/* properties radio btn */
.rs-radio-checked .rs-radio-wrapper .rs-radio-inner:before {
  border-color: #ffb900;
  background-color: #ffb900;
}
.rs-radio-wrapper>input:focus{
  outline: none;

}
.rs-radio-checker:hover {
  outline: none !important;
  border: none !important;
}

/* get rent estimate */
.rent-est-mail-pad input#address-auto-complete {
  padding: 6px 11px ;
}
input.rs-picker-search-input {
  padding: 9px 8px !important;
}
.rs-picker-input:not(.rs-picker-disabled):hover {
  border-color: #ffb900;
}
.rs-picker-input:focus {
  border: none;
  outline: none;
}
.rs-input-group-xs.rs-input-group>.rs-input, .rs-input-group-xs.rs-input-group>.rs-input-group-addon {
  height: 34px;
}
button.rs-btn.rs-btn-primary.rs-btn-sm.rs-btn-disabled.rs-btn-block {
  padding: 10px 0px;
}
/* .rs-nav.rs-nav-default.rs-navbar-nav.rs-navbar-right.rs-nav-horizontal {
  color: #ffb900;
} */
/* .rs-nav.rs-nav-default.rs-navbar-nav.rs-navbar-right.rs-nav-horizontal ul a:hover {
  color: #e0ac00 !important;
} */
.rs-steps-item-status-process .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  border-color: #ffb900;
  background: #ffb900;
  color: #fff;
}
.rs-steps-item-status-finish .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  border-color: #ffb900;
  color: #fff;
}
.rs-steps-item-status-finish .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon:before {
  content: "\f00c";
  color: #ffb900;
}
.rs-steps-item-status-finish .rs-steps-item-tail, .rs-steps-item-status-finish .rs-steps-item-title:after{
  border-color: #ffb900;
}
.rs-picker-toggle-clean{
  right: 38px !important;
}
.rs-pickle-toggle-carrot{
  right:28px !important;
}
/* properties */
.location-dropdown #address-auto-complete{
  padding: 8px 20px !important;
}
.properties-container-wrapper .sidebar .rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 9px !important;
  padding-bottom: 8px !important;
}

/* list property */
.rs-form:not(.rs-form-inline) .rs-form-group:not(:last-child){
  margin-bottom: 12px;
}
.your-place-loc  .rs-btn-sm{
  padding: 10px 10px !important;

}

.btn-color-sett .rs-btn-link{
  color: #ffb900;

}
.btn-color-sett .rs-btn-link:hover{
  color: #c29800;
}

.your-place-loc button.rs-btn.rs-btn-primary.rs-btn-sm {
  background: #ffb900;
}
/* tenant requests */
button.rs-btn.rs-btn-primary.add-button-styled-own.md\:ml-3.mt-3.md\:mt-0.uppercase.rounded-lg.rs-btn-xs:hover{
  background-color:#e0ac00 ;
}
button.rs-btn.rs-btn-primary.add-button-styled-own.md\:ml-3.mt-3.md\:mt-0.uppercase.rounded-lg.rs-btn-xs:focus{
  background-color:#e0ac00 ;
}
button.rs-btn.rs-btn-primary.add-button-styled-own.md\:ml-3.mt-3.md\:mt-0.uppercase.rounded-lg.rs-btn-xs:active{
  background-color:#e0ac00 ;
}


/* properties  card dashboard profile setting */
.rs-btn-toolbar button.rs-btn.rs-btn-primary.rs-btn-xs {
  background: #ffb900;
}
.rs-btn-toolbar .rs-btn-xs{
  padding: 7px 8px;
}
.rs-btn-toolbar button.rs-btn.rs-btn-primary.rs-btn-xs:hover {
  background: #e0ac00;
}

/* dashboard > dashboard */
.rs-btn-toggle-checked {
  background-color: #ffb900;
}
.rs-btn-toggle-checked:hover {
  background-color: #ffb900;
}
.rs-table-hover .rs-table-row:hover .rs-table-cell, .rs-table-hover .rs-table-row:hover .rs-table-cell-group, .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover {
  background: #fffbf2;
}
.radio-button {
  -fx-focus-traversable: false;
  
}
input[type='radio']:checked:after{
  background-color: #ffb900 !important;
}

/* btn ghots */
.rs-btn-ghost{
  color: #ffb900;
  border-color: #ffb900;
}
.rs-btn-ghost:hover{
  color: #e0ac00;
  border-color: #e0ac00;
}
.rs-btn-ghost:active{
  color: #e0ac00;
  border-color: #e0ac00;
}
.rs-btn-ghost:focus{
  color: #e0ac00;
  border-color: #e0ac00;
}
.rs-btn-primary{
  /* color: #ffb900 !; */
  background-color: #ffb900 !important;
}
.rs-btn-primary:hover{
  color: #fff;
  background-color: #e0ac00 !important;
}
.rs-btn-primary:active{
  color: #fff;
  background-color: #e0ac00 !important;
}
.rs-btn-primary:focus{
  color: #fff;
  background-color: #e0ac00 !important;
}
.rs-btn-link{
background-color: none !important;
}
.rs-btn-link:hover{
  color: #e0ac00 !important;
background-color: none !important;

}
.rs-btn-link:active{
  color: #e0ac00 !important;
background-color: none !important;

}
.rs-btn-link:focus{
  color: #e0ac00 !important;
background-color: none !important;

}
.rs-btn-ghost.rs-btn-active.rs-btn-focus, .rs-btn-ghost.rs-btn-active:focus, .rs-btn-ghost.rs-btn-active:hover, .rs-btn-ghost:active.rs-btn-focus, .rs-btn-ghost:active:focus, .rs-btn-ghost:active:hover, .rs-open>.rs-dropdown-toggle.rs-btn-ghost.rs-btn-focus, .rs-open>.rs-dropdown-toggle.rs-btn-ghost:focus, .rs-open>.rs-dropdown-toggle.rs-btn-ghost:hover {
  color: #e0ac00;
  border-color: #e0ac00;
  /* background: #e0ac00; */
}
rs-dropdown-menu>.rs-dropdown-item>.rs-dropdown-item-content:focus, .rs-dropdown-menu>.rs-dropdown-item>.rs-dropdown-item-content:hover {
  text-decoration: none;
  color: #575757;
  background-color: #fffbf2;
}
.rs-input-group:active,.rs-input-group:focus{
  border-color: #e0ac00 !important;
}
.rs-input-group-focus{
  border-color: #e0ac00 !important;
}
/* a:hover{
  color: #e0ac00 !important;
}
a:active{
  color: #e0ac00 !important;
}
a:focus{
  color: #e0ac00 !important;
} */
/* step 2 properties calender */
.rs-calendar-time-dropdown-cell:hover {
  color: #575757;
  background-color: #fffbf2;
}
.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell, .rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell:hover {
  background-color: #ffb900;
  color: #fff;
}
.rs-picker-toolbar-option:focus, .rs-picker-toolbar-option:hover {
  color: #e0ac00;
  text-decoration: underline;
  background-color: transparent;
}
.rs-calendar-show-time-dropdown .rs-calendar-header-title-time {
  color: #e0ac00;
  background: transparent;
}
.rs-radio-checked{
  outline: none;
}
input[type='radio']:focus{
outline: #ffb900;
}

.rs-pagination>li.rs-pagination-btn-active>a, .rs-pagination>li.rs-pagination-btn-active>a.focus, .rs-pagination>li.rs-pagination-btn-active>a:active, .rs-pagination>li.rs-pagination-btn-active>a:active.focus, .rs-pagination>li.rs-pagination-btn-active>a:active:focus, .rs-pagination>li.rs-pagination-btn-active>a:active:hover, .rs-pagination>li.rs-pagination-btn-active>a:focus, .rs-pagination>li.rs-pagination-btn-active>a:hover{
  color: #ffb900;
}
.rs-picker-value-count{
  background: #ffb900;
}

/* input tags */
.rs-picker-input .rs-picker-toggle.rs-btn-xs~.rs-picker-tag-wrapper .rs-tag{
  padding-top: 3px;
  padding-bottom: 3px;
}
.xxs-size{
  width:95% !important;
}

/* cus modal responsive */
@media screen and (max-width:600px) {

  .cus-modal-xxs-size{
    width: 95% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}