.properties-card {
  margin-bottom: 10px;
}
.properties-card .rs-col {
  text-align: center;
}

.properties-card .rs-col.border {
  border-right-width: 1px;
  border-right-color: #f1f1f1;
  border-right-style: solid;
}

.properties-card .action-icon {
  height: 25px;
  width: 25px;
  margin-bottom: 20px;
}

.properties-card .address-first-line {
  font-size: 18px;
  text-align: left;
  margin-left: 10px;
  line-height: 21px;
}

.properties-card .address-second-line {
  font-size: 14px;
  text-align: left;
  color: #a0a0a0;
  margin-left: 10px;
}

.properties-card .amenities-icon {
  font-size: 12px;
  margin-top: 5px;
}
.properties-card .amenities-icon img {
  height: 20px;
  width: 20px;
  margin-left: 10px;
}

a.address-first-line-mobile {
  color: #e0ac00;
}
a.address-first-line-mobile:hover {
  color: #c29800;
}

.rs-radio-checker:hover .rs-radio-wrapper .rs-radio-inner:before {
  border-color: #ffb900;
}
.rs-picker-input.rs-picker-focused, .rs-picker-input:not(.rs-picker-disabled):hover {
  border-color: #ffb900;
}
.rs-picker-select-menu-item.rs-picker-select-menu-item-active, .rs-picker-select-menu-item.rs-picker-select-menu-item-active:hover {
  color: #ffb900;
  background-color: #fffbf2;
}
.rs-picker-select-menu-item.rs-picker-select-menu-item-focus, .rs-picker-select-menu-item:not(.rs-picker-select-menu-item-disabled):hover {
  
  background-color: #fffbf2;
  
}
.rs-input-focus{
  border-color: #ffb900;
}
.rs-input-group-sm.rs-input-group>.rs-input, .rs-input-group-sm.rs-input-group>.rs-input-group-addon {
  height: 38px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm {
  padding-top: 9px !important;
  padding-bottom: 9px;
  
}
.rdw-dropdown-selectedtext{
  color: #ffb900;
}
.rdw-dropdown-selectedtext:hover{
  color: #e0ac00;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn.active, .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border-color: #ffb900;
}
span.rs-picker-toggle-clean {
  right: 27px !important;
  top: 10px !important;
  
}
.rs-picker-toggle-caret {
  right: 9px !important;
  top: 10px !important;

}
.rs-picker-menu .rs-picker-search-bar:after {
  top: 18px;
}
.rs-check-item-focus, .rs-check-item:not(.rs-checkbox-disabled):hover {
  color: #575757;
  background-color: #fffbf2;
}
.rs-checkbox-checker:hover .rs-checkbox-wrapper .rs-checkbox-inner:before {
  border-color: #ffb900;
}
.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before, .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before {
  border-color: #ffb900;
  background-color: #ffb900;
}

/* properties details calender */
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: #fff;
  border-color: #ffb900;
  background-color: #ffb900;
}
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
  color: #fff;
  border-color: #ffb900;
  background-color: #ffb900;
}
.rs-calendar-table-cell-content:hover {
  background-color: #fffbf2;
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border: 1px solid #e0ac00;
  padding: 4px;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
  
  background-color: #ffb900;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus, .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover {
  border-color: #ffb900;
  outline: 0;
}
.rs-picker-select-menu-items .rs-picker-select-menu-group-title:hover {
  background-color: #fffbf2;
}
a:focus, a:hover {
  color: #e0ac00;
}
.rs-picker-toolbar-option{
  color: #ffb900;
}

@media screen and (min-width: 200px) and (max-width: 400px) {
  .address-first-line-mobile {
    font-size: 14px;
  }
  .address-second-line-mobile {
    font-size: 12px;
    text-align: left;
    color: #a0a0a0;
  }
}
